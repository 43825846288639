import React, { useState, useEffect } from 'react';
import appSettings from './app_settings';

import {
    AppLayout, HelpPanel, Cards, Tabs, Icon, Spinner, ContentLayout, Table,
    Container, Header, Box, Pagination, SpaceBetween, ExpandableSection, Modal, ColumnLayout, Button, Badge, Textarea, Select, Alert, Input, TokenGroup, DatePicker
} from '@amzn/awsui-components-react';
import Link from "@amzn/awsui-components-react/polaris/link";

import { useParams } from "react-router-dom";
import '../../index.scss';
import { useSelector, useDispatch } from 'react-redux'

import './styles.scss'

import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import { useHistory } from 'react-router-dom';

import { API, graphqlOperation } from 'aws-amplify';
import Template from '../Templates/Template';
import { glossary, TEMPLATE_APP, TEMPLATE_APP_OUTPUT, TEMPLATE_APP_PERMISSION, TEMPLATE_APP_TEAM, TEMPLATE_APP_SOURCE_DATA_TABLES, TEMPLATE_APP_CHART_OF_ACCOUNTS } from 'src/Ateam-BI/Templates/Constants';
import TemplateMaple from '../Templates/TemplateMaple';
import { favorite, checkIsFavorite } from '../../utilities/Favorites'
import eyeLogo from '../../images/eye-svgrepo-com.svg'
import SideNav from './SideNav';
import { addAliasByType, addAppTags, getAliasesByType, getAppPromise, getAppTags, getFRISCOGroupsWithConsolidatedStatusForQuarter, getFriscoGroupById, getFriscoGroupsForAppId, getFriscoProcessWithTracksForQuarter, getRedshiftTransformedTableDataForProcessId, resetProcessStatus, updateGroups } from '../Templates/Utils/AppActivityUtils';
import { APPROVER_TYPES, FRISCO_STATUS, PROCESS_KEYS, UPLOADER_TYPES } from '../Frisco/Constants';
import * as XLSX from 'xlsx';
import { getEditableFields } from "../Templates/Utils/FormMetadata";
import { canAddEditDeleteApplication, isCorpAdmin } from '../Templates/Utils/Permissions';
import Feedback from 'src/generic-components/UserFeedback/Feedback';
import { performActionOnWorkflow } from 'src/utilities/wasApprovalAPIs';

import { notifyByEmail } from "../../utilities/notificationAPIs"
import { isApprover, isUploaderAllowed } from '../Frisco/AccessUtilities';
import { filterItemsByAccess, getStateNonAdmin, getStateNonAdminPlain } from './Utility';
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { setCurrentAppId } from 'src/store/userAuthSlice';
import CustomMultiInputAdminForSelect from '../Templates/components/CustomMultiInputAdminForSelect';
import CustomGroupsForAppsWithReloadNotifiers from '../Templates/components/CustomGroupsForAppsWithReloadNotifiers';
import CustomMultiInputWithReloadNotifiers from '../Templates/components/CustomMultiInputWithReloadNotifiers';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default (props) => {

    const dispatch = useDispatch();
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const history = useHistory();
    const [NavOpen, setNavOpen] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const userId = useSelector((globalState) => globalState.auth.userId);
    const [isAdmin, setIsAdmin] = useState(false)
    const [toolsOpen, setToolsOpen] = useState(false);
    const [groups, setGroups] = useState([]);
    const [approvers, setApprovers] = useState([]);
    const [notificationSubscribers, setNotificationSubscribers] = useState([]);
    const [reloadNotifiers, setReloadNotifiers] = useState({})
    const [loaders, setLoaders] = useState({})
    const [errorMessages, setErrorMessages] = useState({})
    const [tags, setTags] = useState([]);
    const [creatorAlias, setCreatorAlias] = useState([]);

    const FIELD_NAMES = {
        WORKSTREAMS: 'WORKSTREAMS',
        APPROVERS: 'APPROVERS',
        NOTIFICATION_SUBSCRIBERS: 'NOTIFICATION_SUBSCRIBERS',
        TAGS: 'TAGS',
        CREATOR_ALIAS: 'CREATOR_ALIAS'
    }
    const ALIAS_TYPES = {
        FRISCO_CORP_REPORTING_ADMIN: "FRISCO_CORP_REPORTING_ADMIN",
        FRISCO_CORP_REPORTING_ADMIN_NOTIFY: "FRISCO_CORP_REPORTING_ADMIN_NOTIFY",
        FRISCO_CORP_REPORTING_CREATOR_ALIAS: "FRISCO_CORP_REPORTING_CREATOR_ALIAS"
    }
    useEffect(() => {
        setViewItems()
        return () => {
            // this now gets called when the component unmounts
        };
    }, [currentStage]);


    const setViewItems = async () => {
        let accessPerms = await isCorpAdmin(userId, appSettings[currentStage].appId, currentStage)
        setIsAdmin(accessPerms?.admin)
        setShowLoadingIcon(false)
        loadGroups()
        loadApprovers()
        loadNotificationSubscribers()
        loadCreatorAlias()
        loadTags()
    }

    const loadTags = () => {
        getAppTags(appSettings[currentStage].appId, function onSuccess(result){
            let tagList = []
            result.data.getAppTags.forEach((item) => {
                tagList.push(item.tag)
            })
            setTags(tagList)
            setReloaders(FIELD_NAMES.TAGS, true)
            setLoader(FIELD_NAMES.TAGS, false)
            setReloaders(FIELD_NAMES.TAGS, true)
        }, function onFailure(result){

        })
    }

    const loadGroups = () => {
        getFriscoGroupsForAppId(appSettings[currentStage].appId,
            function onSuccess(result) {
                setGroups(result.data.getFRISCOGroups)
                setReloaders(FIELD_NAMES.WORKSTREAMS, true)
                setLoader(FIELD_NAMES.WORKSTREAMS, false)
                setReloaders(FIELD_NAMES.WORKSTREAMS, true)
            }, function onFailure(result) {

            })
    }

    const loadApprovers = () => {
        getAliasesByType(appSettings[currentStage].appId, ALIAS_TYPES.FRISCO_CORP_REPORTING_ADMIN,
            function onSuccess(result) {
                let aliases = []
                result.data.getAliasesByType.forEach((item) => {
                    aliases.push(item.alias)
                })
                setApprovers(aliases)
                setReloaders(FIELD_NAMES.APPROVERS, true)
                setLoader(FIELD_NAMES.APPROVERS, false)
                setReloaders(FIELD_NAMES.APPROVERS, true)
            }, function onFailure(result) {

            })
    }

    const loadNotificationSubscribers = () => {
        getAliasesByType(appSettings[currentStage].appId, ALIAS_TYPES.FRISCO_CORP_REPORTING_ADMIN_NOTIFY,
            function onSuccess(result) {
                let aliases = []
                result.data.getAliasesByType.forEach((item) => {
                    aliases.push(item.alias)
                })
                setNotificationSubscribers(aliases)
                setReloaders(FIELD_NAMES.NOTIFICATION_SUBSCRIBERS, true)
                setLoader(FIELD_NAMES.NOTIFICATION_SUBSCRIBERS, false)
                setReloaders(FIELD_NAMES.NOTIFICATION_SUBSCRIBERS, true)
            }, function onFailure(result) {

            })
    }

    const loadCreatorAlias = () => {
        getAliasesByType(appSettings[currentStage].appId, ALIAS_TYPES.FRISCO_CORP_REPORTING_CREATOR_ALIAS,
            function onSuccess(result) {
                let aliases = []
                result.data.getAliasesByType.forEach((item) => {
                    aliases.push(item.alias)
                })
                setCreatorAlias(aliases)
                setReloaders(FIELD_NAMES.CREATOR_ALIAS, true)
                setLoader(FIELD_NAMES.CREATOR_ALIAS, false)
                setReloaders(FIELD_NAMES.CREATOR_ALIAS, true)
            }, function onFailure(result) {

            })
    }

    const refresh = async (key) => {
        setError(key, "")
        setLoader(key, true)
        setReloaders(key, false)
        if (key == FIELD_NAMES.WORKSTREAMS) {
            loadGroups()
        } else if (key == FIELD_NAMES.APPROVERS) {
            loadApprovers()
        } else if (key == FIELD_NAMES.NOTIFICATION_SUBSCRIBERS) {
            loadNotificationSubscribers()
        }else if (key == FIELD_NAMES.TAGS) {
            loadTags()
        }else if (key == FIELD_NAMES.CREATOR_ALIAS) {
            loadCreatorAlias()
        }
    }
    const setLoader = (key, value) => {
        let obj = {}
        obj[key] = value
        setLoaders(prevState => {
            return { ...prevState, ...obj };
        });
    }
    const setReloaders = (key, value) => {
        let obj = {}
        obj[key] = value
        setReloadNotifiers(prevState => {
            return { ...prevState, ...obj };
        });

    }
    const updateValue = (fieldName, value) => {
        if (fieldName == FIELD_NAMES.WORKSTREAMS) {
            setGroups(value)
        } else if (fieldName == FIELD_NAMES.APPROVERS) {
            setApprovers(value)
        } else if (fieldName == FIELD_NAMES.NOTIFICATION_SUBSCRIBERS) {
            setNotificationSubscribers(value)
        }else if (fieldName == FIELD_NAMES.TAGS) {
            setTags(value)
        }else if (fieldName == FIELD_NAMES.CREATOR_ALIAS) {
            setCreatorAlias(value)
        }
    }
    const getSelectFieldValues = (fieldName, subKey = null) => {
        if (fieldName == FIELD_NAMES.WORKSTREAMS) {
            return groups
        } else if (fieldName == FIELD_NAMES.APPROVERS) {
            return approvers
        } else if (fieldName == FIELD_NAMES.NOTIFICATION_SUBSCRIBERS) {
            return notificationSubscribers
        }else if (fieldName == FIELD_NAMES.TAGS) {
            return tags
        }else if (fieldName == FIELD_NAMES.CREATOR_ALIAS) {
            return creatorAlias
        }
        return []
    }
    const updateWorkstreams = () => {
        setLoader(FIELD_NAMES.WORKSTREAMS, true)
        setReloaders(FIELD_NAMES.WORKSTREAMS, false)
        updateGroups(appSettings[currentStage].appId, groups, userId,function onSucess(result) {
            refresh(FIELD_NAMES.WORKSTREAMS)
        }, function onFailure(result) {
            refresh(FIELD_NAMES.WORKSTREAMS, true)
            setError(FIELD_NAMES.WORKSTREAMS, "Could not update the groups")
        })
    }

    const updateAlias = (key, type) => {
        setLoader(key, true)
        setReloaders(key, false)
        let aliases = null
        if (key == FIELD_NAMES.APPROVERS) {
            aliases = approvers
        } else if (key == FIELD_NAMES.NOTIFICATION_SUBSCRIBERS) {
            aliases = notificationSubscribers
        }else if (key == FIELD_NAMES.CREATOR_ALIAS) {
            aliases = creatorAlias
        }
        addAliasByType(appSettings[currentStage].appId, type, aliases, userId, function onSucess(result) {
            refresh(key)
        }, function onFailure(result) {
            refresh(key, true)
            setError(key, "Could not update the alias")
        })
    }
    const setError = (key, value) => {
        let obj = {}
        obj[key] = value
        setErrorMessages(prevState => {
            return { ...prevState, ...obj };
        });
    }

    const updateTags = () => {
        setLoader(FIELD_NAMES.TAGS, true)
        setReloaders(FIELD_NAMES.TAGS, false)
        let tagInput = {"tags":[]}
        tags.forEach((tag) => {
            tagInput.tags.push({tag:tag})
        })
        addAppTags(appSettings[currentStage].appId, tagInput, userId, function onSuccess(result){
            refresh(FIELD_NAMES.TAGS)
        }, function onFailure(result){
            refresh(FIELD_NAMES.TAGS)
            setError(FIELD_NAMES.TAGS, "Could not update tags")
        })
    }

    const adminContent = (
        <div className="mainPage">
            <ContentLayout
                headerVariant="high-contrast"
                header={
                    <SpaceBetween size="m">
                        <Header
                            variant="h1"
                            description="Admin panel for editing the configuration"

                        >
                            Admin panel
                        </Header>
                    </SpaceBetween>
                }
            >
                <Container>
                    <ColumnLayout columns={3}>
                        <ExpandableSection
                            variant="container"
                            defaultExpanded={false}
                            headerText={"Workstreams"}
                            headerActions={<Button iconName="refresh" variant="icon" onClick={() => {
                                refresh(FIELD_NAMES.WORKSTREAMS)
                            }}></Button>}
                        >
                            {loaders && loaders[FIELD_NAMES.WORKSTREAMS] ? <Box>
                                <span>Please wait...</span> <Spinner />
                            </Box> : ''}

                            <SpaceBetween direction='vertical'>
                                <span className="small_grey_text_italics">
                                    Adding/updating a workstream
                                </span>

                                {errorMessages[FIELD_NAMES.WORKSTREAMS] ? <Box>
                                    <span className="errorMessage">{errorMessages[FIELD_NAMES.WORKSTREAMS]}</span>
                                </Box> : ''}

                                {
                                    groups ?
                                        <>
                                            <CustomGroupsForAppsWithReloadNotifiers reloadNotifiers={reloadNotifiers} setReloadNotifier={setReloaders}
                                                field={FIELD_NAMES.WORKSTREAMS} updateValue={updateValue} getValues={getSelectFieldValues} appId={appSettings[currentStage].appId}></CustomGroupsForAppsWithReloadNotifiers>
                                            <Box float="right">
                                                <Button variant="primary" disabled={loaders[FIELD_NAMES.WORKSTREAMS] ? true : false} onClick={() => updateWorkstreams()}>Save</Button>
                                            </Box>
                                        </> : <></>
                                }

                            </SpaceBetween>
                        </ExpandableSection>


                        <ExpandableSection
                            variant="container"
                            defaultExpanded={false}
                            headerText={"Reporting approvers"}
                            headerActions={<Button iconName="refresh" variant="icon" onClick={() => {
                                refresh(FIELD_NAMES.APPROVERS)
                            }}></Button>}
                        >
                            {loaders && loaders[FIELD_NAMES.APPROVERS] ? <Box>
                                <span>Please wait...</span> <Spinner />
                            </Box> : ''}

                            <SpaceBetween direction='vertical'>
                                <span className="small_grey_text_italics">
                                    Approvers for report that will be generated on Reporting central
                                </span>
                                {errorMessages[FIELD_NAMES.APPROVERS] ? <Box>
                                    <span className="errorMessage">{errorMessages[FIELD_NAMES.APPROVERS]}</span>
                                </Box> : ''}

                                {
                                    approvers ?
                                        <>
                                            <CustomMultiInputWithReloadNotifiers reloadNotifiers={reloadNotifiers} setReloadNotifier={setReloaders}
                                                field={FIELD_NAMES.APPROVERS} updateValue={updateValue} getValues={getSelectFieldValues} appId={appSettings[currentStage].appId}></CustomMultiInputWithReloadNotifiers>
                                            <Box float="right">
                                                <Button variant="primary" disabled={loaders[FIELD_NAMES.APPROVERS] ? true : false} onClick={() => updateAlias(FIELD_NAMES.APPROVERS, ALIAS_TYPES.FRISCO_CORP_REPORTING_ADMIN)}>Save</Button>
                                            </Box>
                                        </> : <></>
                                }

                            </SpaceBetween>
                        </ExpandableSection>

                        <ExpandableSection
                            variant="container"
                            defaultExpanded={false}
                            headerText={"Notification subscribers"}
                            headerActions={<Button iconName="refresh" variant="icon" onClick={() => {
                                refresh(FIELD_NAMES.NOTIFICATION_SUBSCRIBERS)
                            }}></Button>}
                        >

                            {loaders && loaders[FIELD_NAMES.NOTIFICATION_SUBSCRIBERS] ? <Box>
                                <span>Please wait...</span> <Spinner />
                            </Box> : ''}

                            <SpaceBetween direction='vertical'>
                                <span className="small_grey_text_italics">
                                    Subscribers for events related to the report that is generated on Reporting central
                                </span>
                                {errorMessages[FIELD_NAMES.NOTIFICATION_SUBSCRIBERS] ? <Box>
                                    <span className="errorMessage">{errorMessages[FIELD_NAMES.NOTIFICATION_SUBSCRIBERS]}</span>
                                </Box> : ''}

                                {
                                    notificationSubscribers ?
                                        <>
                                            <CustomMultiInputWithReloadNotifiers reloadNotifiers={reloadNotifiers} setReloadNotifier={setReloaders}
                                                field={FIELD_NAMES.NOTIFICATION_SUBSCRIBERS} updateValue={updateValue} getValues={getSelectFieldValues} appId={appSettings[currentStage].appId}></CustomMultiInputWithReloadNotifiers>
                                            <Box float="right">
                                                <Button variant="primary" disabled={loaders[FIELD_NAMES.NOTIFICATION_SUBSCRIBERS] ? true : false} onClick={() => updateAlias(FIELD_NAMES.NOTIFICATION_SUBSCRIBERS, ALIAS_TYPES.FRISCO_CORP_REPORTING_ADMIN_NOTIFY)}>Save</Button>
                                            </Box>
                                        </> : <></>
                                }

                            </SpaceBetween>
                        </ExpandableSection>

                        <ExpandableSection
                            variant="container"
                            defaultExpanded={false}
                            headerText={"Tags"}
                            headerActions={<Button iconName="refresh" variant="icon" onClick={() => {
                                refresh(FIELD_NAMES.TAGS)
                            }}></Button>}
                        >

                            {loaders && loaders[FIELD_NAMES.TAGS] ? <Box>
                                <span>Please wait...</span> <Spinner />
                            </Box> : ''}

                            <SpaceBetween direction='vertical'>
                                <span className="small_grey_text_italics">
                                    Unique columns that differentiate one leadsheet to another
                                </span>
                                {errorMessages[FIELD_NAMES.TAGS] ? <Box>
                                    <span className="errorMessage">{errorMessages[FIELD_NAMES.TAGS]}</span>
                                </Box> : ''}

                                {
                                    tags ?
                                        <>
                                            <CustomMultiInputWithReloadNotifiers reloadNotifiers={reloadNotifiers} setReloadNotifier={setReloaders}
                                                field={FIELD_NAMES.TAGS} updateValue={updateValue} getValues={getSelectFieldValues} appId={appSettings[currentStage].appId}></CustomMultiInputWithReloadNotifiers>
                                            <Box float="right">
                                                <Button variant="primary" disabled={loaders[FIELD_NAMES.TAGS] ? true : false} onClick={() => updateTags()}>Save</Button>
                                            </Box>
                                        </> : <></>
                                }

                            </SpaceBetween>
                        </ExpandableSection>

                        <ExpandableSection
                            variant="container"
                            defaultExpanded={false}
                            headerText={"Report creator alias"}
                            headerActions={<Button iconName="refresh" variant="icon" onClick={() => {
                                refresh(FIELD_NAMES.CREATOR_ALIAS)
                            }}></Button>}
                        >

                            {loaders && loaders[FIELD_NAMES.CREATOR_ALIAS] ? <Box>
                                <span>Please wait...</span> <Spinner />
                            </Box> : ''}

                            <SpaceBetween direction='vertical'>
                                <span className="small_grey_text_italics">
                                    Alias that will be used as a creator, when a report is generated on reporting central
                                </span>
                                {errorMessages[FIELD_NAMES.CREATOR_ALIAS] ? <Box>
                                    <span className="errorMessage">{errorMessages[FIELD_NAMES.CREATOR_ALIAS]}</span>
                                </Box> : ''}

                                {
                                    creatorAlias ?
                                        <>
                                            <CustomMultiInputWithReloadNotifiers reloadNotifiers={reloadNotifiers} setReloadNotifier={setReloaders} limitCount={1}
                                                field={FIELD_NAMES.CREATOR_ALIAS} updateValue={updateValue} getValues={getSelectFieldValues} appId={appSettings[currentStage].appId}></CustomMultiInputWithReloadNotifiers>
                                            <Box float="right">
                                                <Button variant="primary" disabled={loaders[FIELD_NAMES.CREATOR_ALIAS] ? true : false} onClick={() => updateAlias(FIELD_NAMES.CREATOR_ALIAS, ALIAS_TYPES.FRISCO_CORP_REPORTING_CREATOR_ALIAS)}>Save</Button>
                                            </Box>
                                        </> : <></>
                                }

                            </SpaceBetween>
                        </ExpandableSection>

                    </ColumnLayout>
                </Container>
            </ContentLayout>
        </div>
    )
    const loaderView = (
        <Box>
            <Spinner />
        </Box>
    )

    const Content = (
        <>
            {
                showLoadingIcon ? <Box>{loaderView}</Box> :
                    isAdmin ? <Box>{adminContent}</Box> : <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header="Access denied"
                    >
                        No access to view admin panel
                    </Alert>
            }

        </>
    )


    return (
        <AppLayout
            disableContentPaddings={true}
            content={Content}
            navigation={<SideNav activeHref={`/CorpReportingHome/admin`} id={appSettings[currentStage].appId} isAdmin={isAdmin} />}
            contentType="default"
            navigationOpen={NavOpen}
            onNavigationChange={() => {
                setNavOpen(!NavOpen);
            }}
            toolsHide={true}
            toolsOpen={toolsOpen}
            onToolsChange={() => { setToolsOpen(!toolsOpen) }}
            headerVariant="high-contrast"
        />
    );

};


