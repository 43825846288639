import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Link, SpaceBetween, Header, Button,
    Container, Spinner, Pagination, Alert,  Modal
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import './history.scss'
import checkPageAccess, { checkIfPortalAdmin, checkIfSupportAdmin } from "../../checkPageAccess";
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../../context/AuthContextProvider";
import { Storage } from 'aws-amplify';
import { setCurrentAppId } from '../../store/userAuthSlice';
import { queryFRISCOTransactionsByAppIdProcessIdIndex} from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import moment from 'moment';
import { FLEXICO_TEMPLATE_KEYS, PROCESS_KEYS } from './Constants';
import { configureFlexicoS3, configureFriscoS3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isApprover, isUploaderAllowed } from './AccessUtilities';
import { getFlexicoTemplate, listFlexicoTransactionsByFlexId } from 'src/utilities/FlexicoAPIs';
import { CodeView } from '@amzn/awsui-code-view';


const FlexicoHistory = (props) => {

    const userAuthenticationDetails = useAuth();
    const gridRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch()
    const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [items, setItems] = useState([]);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null)
    const [openEndPagination, setOpenEndPagination] = useState(true)
    const [flexicoTemplateData, setFlexicoTemplateData] = useState({})
    const [transactions, setTransactions] = useState([])
    const [parametersModalData, setParametersModalData] = useState('')
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const userId = useSelector((globalState) => globalState.auth.userId);
    const [parametersVisible, setParametersVisible] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    // const isCurrentAppAuthorised = useSelector((globalState) => globalState.auth.isCurrentAppAuthorized)
    const currentAppId = useSelector(
        (globalState) => globalState.auth.currentAppId
      );
    let { id } = useParams();
    let { fid } = useParams();
    const [backUrl, setBackUrl] = useState(null)
    const limit = 20
    const query_page_batch_size = 100

    const [
        currentPageIndex,
        setCurrentPageIndex
    ] = React.useState(1);

    useEffect(() => {
        configureFlexicoS3()
        loadData()
        setBackUrl(getParam('back'))
        if(id){
            dispatch(setCurrentAppId(id))
        }
        return () => {
            configureGenericS3()
        };
    }, []);

    const getS3FolderPath = (item) => {
        let status = item.processingStatus.toLowerCase()
        switch (status) {
            case 'completed': return 'completed';
            case 'pending':
            case 'processing': return 'pending';
            default: return status
        }
    }

    const processIcon = props => (
        props.data.status === 'Completed' ?
            <Box>
                <Icon name='status-positive' variant='success' ></Icon>
            </Box>

            : <Box>
                <Icon name='status-in-progress' variant='normal' ></Icon>
            </Box>


    )

    const downloadRawFile= async (item) => {
        configureFlexicoS3()
        let fn = `${item.rawFilePath}`
        fn = fn.replace('flexico/','')
        let prefix = 'flexico/'
        /*
        If s3 object name has a space. S3 replaces it with a +. 
        However, if it has a space and +, s3 resolves the space as + hence there is a resolution conflict between + and ' '. 
        S3 also responds with by replacing + as %2B when the file is uploaded via storage. 
        So, we have to replace the + with space and then %2B as + which then creates a correct downloadable key format.
        */
        if(fn.includes('+') && fn.includes('%2B')){
            fn=fn.replaceAll('%2B', 'ABCPLUSTEMP')
        }
        fn=fn.replaceAll('+', ' ')
        const result = await Storage.get(decodeURIComponent(fn).replaceAll('ABCPLUSTEMP','+'), { level: 'public', customPrefix:{public:prefix}, expires: 60 });
        window.open(result);
    }
    const downloadFile = async (item) => {
        configureFlexicoS3()
        let fn = `${item.filePath}`
        fn = fn.replace('flexico/','')
        let prefix = 'flexico/'
        const result = await Storage.get(fn, { level: 'public', customPrefix:{public:prefix}, expires: 60 });
        window.open(result);
    }

    
    const downloadIcon = props => (
        <>{
            (props.data.bucketName && props.data.filePath) ? <SpaceBetween size='m' direction='horizontal'>
            <Button
                variant='inline-icon'
                iconName='download'
                onClick={() => {
                    downloadFile(props.data)
                }
                }></Button>
    
                <>
                {
                    props.data.rawFilePath ?
                     <Button
                variant='inline-link'
                onClick={() => {
                    downloadRawFile(props.data)
                }
                }> [Raw file] </Button>
                : ''
                }</>
            </SpaceBetween>:
            <></>
        }</>
        
        
    )

    const epochToTimestamp = props => (
        <Box>{ moment(new Date(Number(props.data.uploadedTimestamp))).format()   }</Box>
    )
    const epochToTimestampLastUpdatedTime = props => (
        <Box>{ moment(new Date(Number(props.data.lastUpdatedTimestamp))).format()   }</Box>
    )
    const epochToTimestampJobModificationTime = props => (
        <Box>{ moment(new Date(Number(props.data.jobStatusModificationTimestamp))).format()   }</Box>
    )

    const epochToTimestampApprovalRequestedTime = props => (
        props.data?.approvalRequestedTime?
        <Box>{ moment(new Date(Number(props.data?.approvalRequestedTime))).format()   }</Box>:
        <Box></Box>
    )

    const epochToTimestampApprovalModificationTime = props => (
        props.data?.approvalModificationTime?
        <Box>{ moment(new Date(Number(props.data?.approvalModificationTime))).format()   }</Box>:
        <Box></Box>
    )
    

    const processImg = (props) => (
        <Box float="left">
          <img
            alt=""
            className="rounded-circle"
            src={
              "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
              props.data.uploadedBy
            }
            width={20}
            height={20}
          />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://phonetool.amazon.com/users/" + props.data.uploadedBy}
          >
            {props.data.uploadedBy}
          </a>
        </Box>
      );

    const  processImgApprovedBy = (props) => (
        props.data.approvedBy ? <Box float="left">
          <img
            alt=""
            className="rounded-circle"
            src={
              "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
              props.data.approvedBy
            }
            width={20}
            height={20}
          />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://phonetool.amazon.com/users/" + props.data.approvedBy}
          >
            {props.data.approvedBy}
          </a>
        </Box> : <Box></Box>
      );

    
    const parametersButton = (props) => {
        return props.data?.parameters? <Button onClick={() => {
        setParametersVisible(true)
        setParametersModalData(JSON.stringify(JSON.parse(props.data?.parameters), 0, 2))
        setModalLoading(false)
        }}>view</Button>:''
    }

    const approvalStatusButton = (props) => {
        if (props?.data.approvalStatus == 'pending') {
            return  <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='orange_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'completed') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='green_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'rejected') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='red_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'cancelled') {
            return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='grey_badge' >{props.data.approvalStatus}</span> </Link>
          } else if (props.data.approvalStatus == 'NO APPROVAL') {
            return  <span className='blue_badge' >{props.data.approvalStatus}</span> 
          } else {
            if(props.data.approvalId){
                return <Link external href={`/ApprovalsHome/Workflow/${props.data.approvalId}`}> <span className='blue_badge' >{props.data.approvalStatus}</span> </Link>
            }else{
                return <span className='blue_badge' >{props.data.approvalStatus}</span>
            }
          }
    }

    const [columnDefs] = useState([
        { field: 'fileId', width: '300px',  headerName: 'file id',sortable: true, resizable: true },
        { field: 'parameters', width: '120px',  headerName: 'parameters',sortable: true, resizable: true, cellRenderer: parametersButton },
        { field: 'status', width: '200px', headerName: 'Status', sortable: true, resizable: true },
        { field: 'lastUpdatedTimestamp', width: '250px', headerName: 'Last updated', sortable: true,  cellRenderer: epochToTimestampLastUpdatedTime, resizable: true },
        { field: 'uploadedBy', width: '150px', headerName: 'Uploaded by', cellRenderer:processImg, sortable: true, resizable: true },
        { field: 'uploadedTimestamp', width: '250px',  headerName: 'Uploaded time', sortable: true, cellRenderer: epochToTimestamp, resizable: true },
        { field: 'approvalStatus', width: '200px', headerName: 'Approval status', cellRenderer:approvalStatusButton, sortable: true, resizable: true },
        { field: 'approvedBy', width: '150px', headerName: 'Approved by', cellRenderer:processImgApprovedBy, sortable: true, resizable: true },
        { field: 'approvalRequestedTime', width: '250px', headerName: 'Approval requested', cellRenderer: epochToTimestampApprovalRequestedTime,sortable: true, resizable: true },
        { field: 'approvalModificationTime', width: '250px', headerName: 'Approval modification time',cellRenderer: epochToTimestampApprovalModificationTime, sortable: true, resizable: true },
        { field: 'jobStartTime', width: '250px', headerName: 'Job start time', sortable: true, resizable: true },
        { field: '', width: '200px', cellRenderer: downloadIcon }
    ])

    const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ["reset"],
            debounceMs: 200,
        },
    };

    const calculatePageCount = (length) => {
        let rawCount = length / limit
        let roundedCount = Math.trunc(rawCount)
        if (roundedCount < rawCount) {
            roundedCount = roundedCount + 1
        }
        return roundedCount
    }
    const loadPaginatedData = async () => {

        if (transactions?.nextToken) {
            queryFRISCOTransactionsByAppIdProcessIdIndex(id, pid, 100000, transactions.nextToken, function onSuccess(response) {
                setTransactions(response.data.queryFRISCOTransactionsByAppIdProcessIdIndex)
                let qResultJSON = response.data.queryFRISCOTransactionsByAppIdProcessIdIndex.Items
                let lastEvKey = response.data.queryFRISCOTransactionsByAppIdProcessIdIndex.nextToken
                qResultJSON = items.concat(qResultJSON)
                setItems(qResultJSON)
                setTotalPageCount(calculatePageCount(qResultJSON.length))
                setShowLoadingIcon(false)
                setTotalPageCount(calculatePageCount(qResultJSON.length))
                if(lastEvKey){
                    setOpenEndPagination(true)
                }else{
                    // end of records
                    setOpenEndPagination(false)
                }
    
            }, function onFailure(response) {
                setShowLoadingIcon(false)
            })
        }

    }

    const handleAccess = async (data) => {
        let resp = false
        if (data[FLEXICO_TEMPLATE_KEYS.created_by] == userId) {
            resp = true
        } else {
            resp = await checkIfPortalAdmin(userId, currentStage)
            if(!resp){
                resp = await checkIfSupportAdmin(userId, currentStage)
            }
            if (!resp) {
                resp = await isUploaderAllowed(data, userId, currentStage)
            }
            if (!resp) {
                resp = await isApprover(data, userId, currentStage)
            }
        }

        setIsAuthorised(resp)
        setIsCheckingAccess(false)
    }

    const loadData = async () => {
        setShowLoadingIcon(true)
        getFlexicoTemplate(fid, async function onSuccess(response) {
            let res = response.data.getFlexicoTemplate
            setFlexicoTemplateData(res)
            await handleAccess(res)
            setShowLoadingIcon(false);
        }, function onFailure(response) {
            setShowLoadingIcon(false);
            setErrorMessage("Could not load template information")
        })


        listFlexicoTransactionsByFlexId(id, fid, 1000, null, function onSuccess(response) {
            setTransactions(response.data.listFlexicoTransactionsByFlexId)
            let qResultJSON = response.data.listFlexicoTransactionsByFlexId.items
            let lastEvKey = response.data.listFlexicoTransactionsByFlexId.nextToken
            qResultJSON.sort(function (a, b) {
                var dateA = new Date(Number(a.lastUpdatedTimestamp)), dateB = new Date(Number(b.lastUpdatedTimestamp));
                return dateB - dateA;
            });
            setItems(qResultJSON)
            setTotalPageCount(calculatePageCount(qResultJSON.length))
            setShowLoadingIcon(false)
            setOpenEndPagination(true)
            setCurrentPageIndex(1)
            if(lastEvKey){
                setOpenEndPagination(true)
            }else{
                // end of records
                setOpenEndPagination(false)
            }
        },function onFailure(error) {
            setShowLoadingIcon(false)
        })
    }

    const onPageChanged = (detail) => {
        setCurrentPageIndex(detail.currentPageIndex)
        if (detail.currentPageIndex == totalPageCount && openEndPagination) {
            loadPaginatedData()
        }
    }

    const getPaginatedItems = () => {
        let start = limit * (currentPageIndex - 1)
        return items.slice(start, start + limit)
    }
    const getParam= (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
      }

    const content = (
        <div>

            {
                isAuthorised?
                <Box>
                    <SpaceBetween size='l'>
                    <Container
                        header={
                            <Header
                                variant='h2'
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant='normal' onClick={() => { history.push(`/App/${id}/flexico/${fid}/upload`) }}>
                                            Execute
                                        </Button>
                                    </SpaceBetween>
                                }
                                description=""
                            >Execute job


                            </Header>
                        }
                    >
                    </Container>

                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">

                                            <Button variant='normal' iconName='refresh' onClick={loadData}></Button>
                                        </SpaceBetween>
                                    }
                                    description="The following table shows all the executions."
                                >Execution history
                                    {showLoadingIcon ?
                                        <Spinner size='normal'></Spinner> : <div></div>
                                    }

                                </Header>
                            }
                            fitHeight
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: 400, width: "100%" }}
                            >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={getPaginatedItems()}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    rowSelection='single'
                                    animateRows={true}
                                    rowHeight={40}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>
                            <div className='pagination_box'>
                                <div className='paginator'>
                                    {openEndPagination ?
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                onPageChanged(detail)
                                            }
                                            openEnd
                                            pagesCount={totalPageCount}
                                        /> :
                                        <Pagination
                                            currentPageIndex={currentPageIndex}
                                            onChange={({ detail }) =>
                                                onPageChanged(detail)
                                            }
                                            pagesCount={totalPageCount}
                                        />}
                                </div>
                            </div>
                        </Container>
                    </SpaceBetween>
                    <Modal
                onDismiss={() => {
                    setParametersVisible(false)
                    setParametersModalData('')
                }}
                visible={parametersVisible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" onClick={() => {
                                setParametersVisible(false)
                                setParametersModalData('')
                            }}>
                                Close
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <CodeView
      content={parametersModalData}
    />
            </Modal>
                    </Box> : isCheckingAccess ? <div>
                        <WaitForPageAccess></WaitForPageAccess>
                    </div> :
                        <Alert
                            statusIconAriaLabel="Error"
                            type="error"
                            header="Access denied"
                        >
                            No access to view
                        </Alert>
            }
        </div>
    )

    return (
        <AppLayout
            content={content}
            navigation={<SideNav activeHref={`/App/${id}/flexico/${fid}/history`} id={id} fid={fid} process={flexicoTemplateData} back={backUrl}/>}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            toolsHide={true}
            headerVariant="high-contrast"
        ></AppLayout>
    )
}



export default FlexicoHistory

