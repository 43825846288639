import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  AppLayout,
  HelpPanel,
  Box,
  SpaceBetween,
  Header,
  Button,
  BreadcrumbGroup,
  Container,
  Alert,
  Icon,
  Spinner,
  ColumnLayout,
  ExpandableSection,
  Checkbox,
  SplitPanel,
  Flashbar,
  ProgressBar,
} from "@amzn/awsui-components-react";

import axios from "axios";

import SideNav from "./SideNav";
import appSettings from "./app_settings";

import { useHistory, useLocation } from "react-router-dom";
import checkPageAccess from "../../checkPageAccess";
import AccessDenied from "../../AccessDenied";
import WaitForPageAccess from "../../WaitForPageAccess";

import { setCurrentComputeId } from "../../store/userBoffinSlice";

import { AgGridReact } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { setCurrentAppId } from "../../store/userAuthSlice";
import { API, graphqlOperation } from "aws-amplify";
import { useAuth } from "../../context/AuthContextProvider";
import {Board, BoardItem} from "@amzn/awsui-board-components";
import { boardI18nStrings, boardItemI18nStrings } from 'src/i18n-strings/board-I18nStrings';

const ViewAllItems = () => {
  const userAuthenticationDetails = useAuth();

  const userLDAPS = userAuthenticationDetails.USER_LDAP_GROUPS;
  const dispatch = useDispatch();

  const history = useHistory();
  const userId = useSelector((globalState) => globalState.auth.userId);
  const gridRef = useRef();

  const [isCheckingAccess, setIsCheckingAccess] = useState(true);
  const isCurrentAppAuthorised = useSelector(
    (globalState) => globalState.auth.isCurrentAppAuthorized
  );
  const [isAuthorized, setIsAuthorized] = useState();

  const currentAppId = useSelector(
    (globalState) => globalState.auth.currentAppId
  );

  const [items, setItems] = useState([]);
  const [processingStatus, setProcessingStatus] = useState("info");
  const [currentStatusId, setCurrentStatusId] = useState("");

  const [statusUpdate, setStatusUpdate] = useState(
    "No status update at the moment."
  );
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const [boardItems, setBoardItems] = useState([
    { id: "1", rowSpan: 5, columnSpan: 6,data:{}}
  ]);

  // const [currentPageIndex, setCurrentPageIndex] = useState(1);
  // const [pageItems, setPageItems] = useState([]);
  // const [tablePages, setTablePages] = useState(1);

  const [currentId, setCurrentId] = useState("");
  const [selectedItem, setSelectedItem] = useState([]);

  const [showLoadingIcon, setShowLoadingIcon] = useState(true);
  const [showAllItems, setShowAllItems] = useState(false);

  useEffect(() => {
    if (currentAppId !== appSettings.appId) {
      dispatch(setCurrentAppId(appSettings.appId));
    }
    setIsAuthorized(isCurrentAppAuthorised);


    return () => {
      // this now gets called when the component unmounts
    };
  }, [ isCurrentAppAuthorised]);

  useEffect(() => {
    if(isAuthorized === true || isAuthorized === false){
      setIsCheckingAccess(!isCheckingAccess);
    } 
  }, [isAuthorized]);

  useEffect(() => {
    
    loadData();

    //   Subscribe here
    var mQry = `
            subscription MySubscription {
                onUpdateUI4BI_BoffinComputeInfo {
                id
                processStatus
                percentageCompleted
                }
            }
            `;

    const subscription = API.graphql(graphqlOperation(mQry)).subscribe({
      next: ({ provider, value }) => {
        // console.log({ provider, value })
        var currentStatusId = value.data.onUpdateUI4BI_BoffinComputeInfo.id;
        var currentStatus =
          value.data.onUpdateUI4BI_BoffinComputeInfo.processStatus;
        var pc = parseInt(
          value.data.onUpdateUI4BI_BoffinComputeInfo.percentageCompleted
        );
        setCurrentStatusId(currentStatusId);
        setStatusUpdate(currentStatus);
        setPercentageCompleted(pc);
        if (currentStatus === "Processing has completed") {
          setProcessingStatus("success");
        }
      },
      error: (error) => console.warn(error),
    });

    return () => {
      // this now gets called when the component unmounts
      subscription.unsubscribe();
    };
  }, [statusUpdate]);

  //
  const openButtonClick = () => {
    if (selectedItem.length == 0) {
      console.log("Select id from the table");
      return;
    }
    if (selectedItem.processStatus === "Processing has completed") {
      history.push("/BoffinEditItem");
    } else {
      console.log("Processing in progress...");
    }
  };
  const openButton = (props) =>
    props.data.processStatus === "Processing has completed" ? (
      <Button
        variant="inline-icon"
        iconName="folder-open"
        onClick={() => {
          //  console.log(props.data.id)
          setCurrentId(props.data.id);
          dispatch(setCurrentComputeId(props.data.id));
          history.push("BoffinEditItem");
        }}
      ></Button>
    ) : (
      <div>
        <Icon name="status-in-progress"></Icon>
      </div>
    );
  const editButton = (props) => (
    <Button
      variant="inline-icon"
      iconName="edit"
      onClick={() => {
        //  console.log(props.data.id)
        setCurrentId(props.data.id);
        dispatch(setCurrentComputeId(props.data.id));
        if (props.data.processStatus === "Processing has completed") {
          history.push("BoffinEditItem");
        } else {
          console.log("Processing in progress...");
        }
      }}
    ></Button>
  );

  const processIcon = (props) =>
    props.data.processStatus === "Processing has completed" ? (
      <div>
        <Icon name="status-positive" variant="success"></Icon>
      </div>
    ) : (
      <div>
        <Icon name="status-in-progress" variant="normal"></Icon>
        &nbsp; {props.data.processStatus}
      </div>
    );
  const processImg = (props) => (
    <div>
      <img
        alt=""
        className="rounded-circle"
        src={
          "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
          props.data.userId
        }
        width={20}
        height={20}
      />
      &nbsp;
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={"https://phonetool.amazon.com/users/" + props.data.userId}
      >
        {props.data.userId}
      </a>
    </div>
  );

  const defaultColDef = {
    // set filtering on for all columns
    filter: true,
    filterParams: {
      buttons: ["reset"],
      debounceMs: 200,
    },
  };

  const [columnDefs] = useState([
    {
      field: "Open",
      width: "70px",
      cellRenderer: openButton,
      sortable: false,
      filter: false,
    },
    // { field: '' ,  width: '60px', cellRenderer:editButton, sortable: false, resizable: false, filter:false},
    { field: "createDate", width: "250px", sortable: true, resizable: true },
    {
      field: "processStatus",
      width: "250px",
      sortable: true,
      resizable: true,
      cellRenderer: processIcon,
    },
    { field: "postingStatus", sortable: true, resizable: true },
    {
      field: "userId",
      width: "150px",
      sortable: true,
      resizable: true,
      cellRenderer: processImg,
    },
    { field: "accounts", width: "150px", sortable: true, resizable: true },
    { field: "teamName", width: "150px", sortable: true, resizable: true },
    { field: "selectedLedgers", width: "150px", resizable: true },
    {
      field: "selectedCompanyCodes",
      width: "150px",
      sortable: true,
      resizable: true,
    },
    { field: "periodMonth", width: "150px", resizable: true },
    { field: "periodYear", width: "150px", resizable: true },
    { field: "id", width: "300px", resizable: true },
    { field: "" },
  ]);

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      history.push(ev.detail.href.substring(1));
    }
  };

  const onSelectionChanged = () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    if (selectedRows.length > 0) {
      setSelectedItem(selectedRows[0]);
      setCurrentId(selectedRows[0].id);
      dispatch(setCurrentComputeId(selectedRows[0].id));
    }
  };

  const breadItems = [
    { text: "Home", href: "/Home" },
    { text: "ATeam BI", href: "/ATeamBIHome" },
    { text: "Boffin", href: "/BoffinHome" },
    { text: "Review", href: "" },
  ];

  const loadData = async () => {
    setShowLoadingIcon(true);
    const variables = {
      limit: 100000
  }
  let computes = []
    if (showAllItems == false) {
      var mQry =
        `query MyQuery($limit: Int!, $nextToken: String) {
                    listUI4BI_BoffinComputeInfos(limit: $limit , filter: {userId: {eq: "` +
        userId +
        `"}}, nextToken: $nextToken){
                    items {
                        accounts
                        createDate
                        datasets
                        id
                        jeTypes
                        ledgerTypes
                        pre_signed_url
                        teamName
                        processRow
                        processStatus
                        userId
                        showPostingButton
                        filterOption
                        selectedLedgers
                        periodMonth
                        periodYear
                        showPostingButton
                        isAllJEPosted
                        wdLink
                        selectedCompanyCodes
                        postingStatus
                    }
                        nextToken
                    }
                }
            `;
    }
    if (showAllItems) {
      var mQry = `query MyQuery ($limit: Int!, $nextToken: String) {
                    listUI4BI_BoffinComputeInfos(limit: $limit, nextToken: $nextToken){
                    items {
                        accounts
                        createDate
                        datasets
                        id
                        jeTypes
                        ledgerTypes
                        pre_signed_url
                        teamName
                        processRow
                        processStatus
                        userId
                        showPostingButton
                        filterOption
                        selectedLedgers
                        periodMonth
                        periodYear
                        showPostingButton
                        isAllJEPosted
                        wdLink
                        selectedCompanyCodes
                        postingStatus
                    }
                        nextToken
                    }
                }
            `;
    }

    // console.log(mQry)
    let response = await API.graphql(graphqlOperation(mQry, variables));

    computes = computes.concat(response.data.listUI4BI_BoffinComputeInfos.items)
    while (response.data.listUI4BI_BoffinComputeInfos.nextToken) {
        variables["nextToken"] = response.data.listUI4BI_BoffinComputeInfos.nextToken
        response = await API.graphql(graphqlOperation(mQry, variables));
        computes = computes.concat(response.data.listUI4BI_BoffinComputeInfos.items)
    }
    computes.sort((a,b) => {
      var dateA = new Date(a.createDate),
      dateB = new Date(b.createDate);
    return dateB - dateA;
    });


    setItems(computes);
    setShowLoadingIcon(false);
  };

  const executeRecompute = async () => {
    let body =
      `{
          
            "processName" : "boffin",
            "scriptName"  : "ReComputeNew",
            "parameters"  : {"computeId": "` +
      selectedItem.id +
      `"}
          }`;
    const ApiGateWayEndpoint = Amplify.Cache.config.ApiGateWayEndpoint;
    let url = ApiGateWayEndpoint + "/executeOnDemand";
    axios.post(url, body).then((response) => {
      console.log(response.status);
    });
  };
  const detailView = (
    <Container>
      {selectedItem.length != 0 ? (
        <ColumnLayout columns={3}>
          <Box variant="awsui-key-label">
            File id
            <Box>{selectedItem.id}</Box>
          </Box>
          {/* <Box variant='awsui-key-label'>Action
                <Box color='text-body-secondary'>
                    <Icon variant='warning' name='status-info'></Icon> &nbsp;
                    Click on this button to initiate recompute. Use this option to execute 
                    the recompute, incase the processing is stuck. Click on the button, 
                    and wait for the updates to flow in.
                </Box>
                <Button on onClick={()=>{executeRecompute()}}>ReCompute</Button>
            </Box> */}
        </ColumnLayout>
      ) : (
        <Box variant="p" color="text-status-error">
          Select any row from above table
        </Box>
      )}
    </Container>
  );

  const content = (
    <SpaceBetween direction="vertical" size="m">
      {isAuthorized ? (
        <Box>
          <Box></Box>
          <Container
            header={
              <Header
                variant="h2"
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    {/* <Button variant='normal' onClick={()=>openButtonClick()}>View Details</Button> */}
                    {showLoadingIcon ? (
                      <Box>
                        <Spinner size="normal"></Spinner>
                      </Box>
                    ) : (
                      <Box></Box>
                    )}
                    <Button
                      variant="normal"
                      iconName="refresh"
                      onClick={() => loadData()}
                    >
                      Refresh
                    </Button>
                    <Checkbox
                      checked={showAllItems}
                      onChange={() => {
                        setShowAllItems(!showAllItems);
                      }}
                    >
                      Show All
                    </Checkbox>
                  </SpaceBetween>
                }
                description="The following table shows all the computes executed."
              >
                Computes
              </Header>
            }
          >
            <Board
              renderItem={(item) => (
                <BoardItem i18nStrings={boardItemI18nStrings}>
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "92%", width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={items}
                      defaultColDef={defaultColDef}
                      columnDefs={columnDefs}
                      rowSelection="single"
                      animateRows={true}
                      rowHeight={30}
                      checkboxSelection={true}
                      onSelectionChanged={onSelectionChanged}
                      enableCellTextSelection={true}
                    ></AgGridReact>
                  </div>
                </BoardItem>
              )}
              onItemsChange={(event) => setBoardItems(event.detail.items)}
              items={boardItems}
              i18nStrings={boardI18nStrings}
            />
          </Container>
          <p></p>

          <p></p>
        </Box>
      ) : isCheckingAccess ? (
        <div>
          <WaitForPageAccess></WaitForPageAccess>
        </div>
      ) : (
        <div>
          <AccessDenied appName={appSettings.appName}></AccessDenied>
        </div>
      )}
    </SpaceBetween>
  );

  const helpContent = (
    <HelpPanel
      header={
        <div>
          <div></div>
          <h2>Step #2: Override</h2>
        </div>
      }
    >
      <div>
        <ExpandableSection headerText="Compute Status">
          <Alert header={currentStatusId} type={processingStatus}>
            <Box variant="pre">{statusUpdate}</Box>
          </Alert>
        </ExpandableSection>
      </div>
    </HelpPanel>
  );
  const SPLIT_PANEL_I18NSTRINGS = {
    preferencesTitle: "Split panel preferences",
    preferencesPositionLabel: "Split panel position",
    preferencesPositionDescription:
      "Choose the default split panel position for the service.",
    preferencesPositionSide: "Side",
    preferencesPositionBottom: "Side",
    preferencesConfirm: "Confirm",
    preferencesCancel: "Cancel",
    closeButtonAriaLabel: "Close panel",
    openButtonAriaLabel: "Open panel",
    resizeHandleAriaLabel: "Resize split panel",
  };
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={breadItems}
          onFollow={(event) => onFollowHandler(event)}
        ></BreadcrumbGroup>
      }
      content={content}
      navigation={<SideNav activeHref="/BoffinViewAllItems" />}
      headerSelector="#TopBar"
      navigationHide={false}
      splitPanel={
        <SplitPanel
          closeBehavior="collapse"
          header="Preview"
          i18nStrings={SPLIT_PANEL_I18NSTRINGS}
        >
          {detailView}
        </SplitPanel>
      }
      tools={helpContent}
      maxContentWidth = {Number.MAX_VALUE}
      headerVariant="high-contrast"
    ></AppLayout>
  );
};

export default ViewAllItems;
