import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'

import {
    AppLayout, HelpPanel, Icon, BreadcrumbGroup, Box, Table, SpaceBetween, Header, Button,
    Container, Spinner, Pagination, Alert,  Modal
} from '@amzn/awsui-components-react';
import SideNav from './SideNav';
import AccessDenied from '../../AccessDenied'
import WaitForPageAccess from '../../WaitForPageAccess'
import './history.scss'
import checkPageAccess, { checkIfPortalAdmin, checkIfSupportAdmin } from "../../checkPageAccess";
import { API, graphqlOperation } from 'aws-amplify';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useAuth } from "../../context/AuthContextProvider";
import { Storage } from 'aws-amplify';
import { setCurrentAppId } from '../../store/userAuthSlice';
import moment from 'moment';
import { PROCESS_KEYS } from './Constants';
import { configureLogicoS3, configureGenericS3 } from 'src/context/AuthContextUtility'
import { isApprover, isUploaderAllowed } from './AccessUtilities';
import { getLogicoTransactionsByLogicoProcessId } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"
import { getLogicoProcessById } from "src/Ateam-BI/Templates/Utils/AppActivityUtils"



const LogicoHistory = (props) => {

    const userAuthenticationDetails = useAuth();
    const gridRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch()
    const [isCheckingAccess, setIsCheckingAccess] = useState(true);
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [showLoadingIcon, setShowLoadingIcon] = useState(true);
    const [processData, setProcessData] = useState({})
    const [transactions, setTransactions] = useState([])
    const [loadErrorData, setLoadErrorData] = useState([])
    const currentStage = useSelector((globalState) => globalState.auth.currentStage)
    const userId = useSelector((globalState) => globalState.auth.userId);
    const [visible, setVisible] = useState(false)
 
    let { id } = useParams();
    let { lpid } = useParams();
    const [backUrl, setBackUrl] = useState(null)



    useEffect(() => {
        configureLogicoS3()
        loadData()
        setBackUrl(getParam('back'))
        if(id){
            dispatch(setCurrentAppId(id))
        }
        return () => {
            configureGenericS3()
        };
    }, []);


    const processIcon = props => (
        props.data.status === 'Completed' ?
            <Box>
                <Icon name='status-positive' variant='success' ></Icon>
            </Box>

            : <Box>
                <Icon name='status-in-progress' variant='normal' ></Icon>
            </Box>


    )

    const downloadFile = async (item) => {
        configureLogicoS3()
        let fn = `${item.filePath}`
        fn = fn.replace('logico/','')
        let prefix = 'logico/'
        const result = await Storage.get(fn, { level: 'public', customPrefix:{public:prefix}, expires: 60 });
        window.open(result);
    }

    const downloadIcon = props => (
        <SpaceBetween size='m' direction='horizontal'>
        <Button
            variant='inline-icon'
            iconName='download'
            onClick={() => {
                downloadFile(props.data)
            }
            }></Button>
        </SpaceBetween>
        
    )

    const epochToTimestamp = props => (
        <Box>{ moment(new Date(Number(props.data.uploadedTimeStamp))).format()   }</Box>
    )

    

    const processImg = (props) => (
        <Box float="left">
          <img
            alt=""
            className="rounded-circle"
            src={
              "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" +
              props.data.uploadedBy
            }
            width={20}
            height={20}
          />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://phonetool.amazon.com/users/" + props.data.uploadedBy}
          >
            {props.data.uploadedBy}
          </a>
        </Box>
      );


    const [columnDefs] = useState([
        { field: 'fileId', width: '300px',  headerName: 'file id',sortable: true, resizable: true },
        { field: 'status', width: '200px', headerName: 'Status', sortable: true, resizable: true },
        { field: 'uploadedBy', width: '150px', headerName: 'Uploaded by', cellRenderer:processImg, sortable: true, resizable: true },
        { field: 'uploadedTimestamp', width: '250px',  headerName: 'Uploaded time', sortable: true, cellRenderer: epochToTimestamp, resizable: true },
        { field: '', headerName: 'Download uploaded file', width: '200px', cellRenderer: downloadIcon }
    ])

    const defaultColDef = {
        // set filtering on for all columns
        filter: true,
        filterParams: {
            buttons: ["reset"],
            debounceMs: 200,
        },
    };

    const handleAccess = async (data) => {
        let resp = false
        if (data[PROCESS_KEYS.created_by] == userId) {
            resp = true
        } else {
            resp = await checkIfPortalAdmin(userId, currentStage)
            if(!resp){
                resp = await checkIfSupportAdmin(userId, currentStage)
            }
            
            if (!resp) {
                resp = await isUploaderAllowed(data, userId, currentStage)
            }
            if (!resp) {
                resp = await isApprover(data, userId, currentStage)
            }
        }

        setIsAuthorised(resp)
        setIsCheckingAccess(false)
    }

    const loadData = async () => {
        setShowLoadingIcon(true)
        getLogicoProcessById(lpid, async function onSuccess(response) {
            setProcessData(response.data.getLogicoProcessByID)
            await handleAccess(response.data.getLogicoProcessByID)
        }, function onFailure(response) {
        })


        const transactions = await getLogicoTransactionsByLogicoProcessId(lpid)
        setTransactions(transactions)
        setShowLoadingIcon(false)

    }

   

    const getParam= (key) => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
        const value = params.get(key);
        return value;
      }

    
    const content = (
        <div>

            {
                isAuthorised?
                <Box>
                    <SpaceBetween size='l'>
            
                        <Container
                            header={
                                <Header
                                    variant='h2'
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">

                                            <Button variant='normal' iconName='refresh' onClick={loadData}></Button>
                                        </SpaceBetween>
                                    }
                                    description="The following table shows all the files uploaded."
                                >File Uploads
                                    {showLoadingIcon ?
                                        <Spinner size='normal'></Spinner> : <div></div>
                                    }

                                </Header>
                            }
                            fitHeight
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: 400, width: "100%" }}
                            >
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={transactions}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    rowSelection='single'
                                    animateRows={true}
                                    rowHeight={40}
                                    enableCellTextSelection={true}
                                >
                                </AgGridReact>
                            </div>
                         
                        </Container>
                    </SpaceBetween>
                    <Modal
                onDismiss={() => {
                    setVisible(false)
                    setLoadErrorData([])
                }}
                visible={visible}
                size="max"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="primary" onClick={() => {
                                setVisible(false)
                                setLoadErrorData([])
                            }}>
                                Close
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                

            </Modal>
                    </Box> : isCheckingAccess ? <div>
                        <WaitForPageAccess></WaitForPageAccess>
                    </div> :
                        <Alert
                            statusIconAriaLabel="Error"
                            type="error"
                            header="Access denied"
                        >
                            No access to view
                        </Alert>
            }
        </div>
    )

    return (
        <AppLayout
            content={content}
            navigation={<SideNav activeHref={`/App/${id}/LogicoProcess/${lpid}/history`} id={id} lpid={lpid} process={processData} back={backUrl}/>}
            headerSelector="#TopBar"
            navigationHide={false}
            disableBodyScroll={true}
            toolsHide={true}
            headerVariant="high-contrast"
        ></AppLayout>
    )
}



export default LogicoHistory

